import sdk from 'services/vigilanceHubService'
import { getCurrentLanguage } from 'hooks/useTranslate'
import _ from 'lodash'
import * as constants from './constants'

class DrugsActions {
  searchDrugs = ({ query, findAll = false, watchListOnly = false }) => ({
    type: constants.SEARCH_DRUGS,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const organisationId = _.get(config, 'websiteConfig.vigilanceHubOrganisationId')
      const languageCode = getCurrentLanguage() || null
         
      const params = {
        organisationId,
        languageCode
      }

      if (query) {
        params.query = query
        params.size = 100
        params.languageCode = languageCode
      }

      if (findAll === true) {
        params.findAll = true
      }

      if (watchListOnly === true) {
        params.watchListOnly = true
      }

      return sdk.drugs
      .searchDrugs(params)
      }
  })

  searchAllDrugs = () => ({
    type: constants.SEARCH_ALL_DRUGS,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const organisationId = _.get(config, 'websiteConfig.vigilanceHubOrganisationId')
      const languageCode = getCurrentLanguage() || null
         
      const params = {
        organisationId,
        languageCode,
        findAll: true
      }

      return sdk.drugs
      .searchDrugs(params)
      }
  })

  fetchDrug = ({ id }) => ({
    type: constants.FETCH_DRUG,
    promise: (dispatch, getState) => {
      return sdk.drugs
      .fetchDrug({ id })
      }
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new DrugsActions()